import { React } from "react";
import "./termin.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Hero,
  Grid,
  Modal,
  Preloader,
} from "../../components";
import { CompanyName } from "../../data";
import {
  TerminpageHero,
  TerminpageMeta,
} from "../../data/DataTerminpage";
import { HomepageModal } from "../../data/DataHomepage";
import useLoginStatus from "../../hooks/loginCheck";

const CompanyNameTitle = CompanyName;

function Termin() {
  const hero = TerminpageHero;
  const meta = TerminpageMeta;
  const modal = HomepageModal;
  const isLoggedIn = useLoginStatus();


  return (
    <div className="content">
     {isLoggedIn && (
        <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "termin-page",
              isFull: true,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Termin</title>
              <meta name="description" content={meta} />
            </Helmet>

            <Hero hero={hero} />
          </Grid>
          <Grid
            grid={{
              className: "page",
              pageName: "termin-page no-padding",
              isFull: false,
            }}
          >
            <iframe id="iframe-termin"  title="Termin Online buchen" src="https://www.etermin.net/xs-barbershop" className="termin-iframe"/>
            <Modal modal={modal.filter((modal) => modal.section === 1)} />
            <Preloader />
          </Grid>
        </HelmetProvider>
      )}
    </div>
  );
}

export default Termin;
